import React from "react";

const projects = [
  {
    name: "Intern Insider",
    url: "https://www.interninsider.me/",
    techStack: "Node, Express, AWS Lambda, Docker",
    date: "Jan 2025 - Ongoing",
    bullets: [
      "A newsletter and job posting platform to notify students of job postings in North America with 50,000+ users!",
      "Feel free to subscribe to be notified for openings!"
    ],
  },
  {
    name: "InternDB",
    url: "https://interndb.io",
    techStack: "React, Node, Express, MongoDB, Docker, Google Cloud",
    date: "Jul 2024 - Ongoing",
    bullets: [
      "A website to allow students in North America to post tech internship reviews!",
      "Please leave your internship reviews! We would really appreciate it :)",
    ],
  },
  {
    name: "SkinfoCare (Hackthe6ix 2024)",
    url: "https://devpost.com/software/skinfocare",
    techStack: "Next, FastAPI, MongoDB, Tailwind CSS",
    date: "Aug 2024",
    bullets: [
      "Created a mobile app that analyzes barcodes of skin care products to display personalized metrics based on skin tone, allergies, and more.",
    ],
  },
  {
    name: "InterviewAId (UC Berkeley AI Hackathon 2024)",
    url: "https://devpost.com/software/interview-aid",
    techStack: "Next, FastAPI, Docker, AWS, Socket.io, Intel Dev Cloud, Hume.ai, Groq, Llama3",
    date: "Jun 2024",
    bullets: [
      "A website that allows people to practice live technical interviews with a bot that communicates, giving feedback on input code and analyzing facial expressions to give a rating on behaviourals.",
    ],
  },
  {
    name: "TuneAI (CalHacks 2023 Winner)",
    url: "https://devpost.com/software/tuneai",
    techStack: "React, Flask, Socket.IO, PyTorch, Docker, Intel Dev Cloud, Llama3",
    date: "Oct 2023",
    bullets: [
      "Built a website that leverages generative AI to create unique audio from videos and images, winning Best Use of Intel Developer Cloud at UC Berkeley's CalHacks 2023.",
    ],
  },
  {
    name: "QuickSort (HackHarvard 2023)",
    url: "https://devpost.com/software/quicksort-oghv1z",
    techStack: "React Native, Node, Express, PostgreSQL, PyTorch, Google Cloud",
    date: "Oct 2023",
    bullets: [
      "A mobile app that analyzes pictures of objects and determines the correct recycling category to aim for proper sorting of trash.",
    ],
  },
  {
    name: "Rent-a-Lot (DubHacks 2023)",
    url: "https://devpost.com/software/rent-a-lot",
    techStack: "React Native, Node, Express, CockroachDB",
    date: "Oct 2023",
    bullets: [
      "Developed a mobile app that allows people to reserve private parking spaces to ensure avoid situations of finding spots for important events.",
    ],
  },
  {
    name: "Flock (ProduHacks 2023)",
    url: "https://devpost.com/software/flock-geuf3p",
    techStack: "React Native, Socket.io, SQLite",
    date: "Apr 2023",
    bullets: [
      "Created a mobile application to allow for students at UBC to create in-person study groups around campus.",
    ],
  },
];

const Projects = () => (
  <section className="section">
    <h2>Projects</h2>
    {projects.map((project, index) => (
      <div className="project-item card" key={index}>
        <h3>
          <a
            href={project.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#2575fc", display: "flex", alignItems: "center", gap: "8px" }}
          >
            {project.name}
            <span
              style={{
                fontSize: "0.9rem",
                color: "#2575fc",
              }}
            >
              🔗
            </span>
          </a>
        </h3>
        <p className="date">{project.date}</p>
        <p>
          <strong>Tech Stack:</strong> {project.techStack}
        </p>
        <ul>
          {project.bullets.map((bullet, i) => (
            <li key={i}>{bullet}</li>
          ))}
        </ul>
      </div>
    ))}
  </section>
);

export default Projects;
