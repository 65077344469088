import React from "react";
import "../App.css";

const experiences = [
  {
    logo: require("../images/Affirm.jpg"),
    company: "Affirm",
    role: "Incoming Software Engineer",
    techStack: "Kafka, Flink, Spark",
    date: "Aug 2025 Start",
    bullets: [
      "Infrastructure on the Data Platform Team :)",
    ],
  },
  {
    logo: require("../images/Tesla.jpg"),
    company: "Tesla",
    role: "Software Engineer Intern",
    techStack: "Spring, Flask, Kafka, MongoDB, MySQL",
    date: "Sept 2024 - Dec 2024",
    bullets: [
      "Created a REST service in Spring and MongoDB to allow Tesla to blacklist fraudulent users along with a CRON-based temporary safelist feature in production.",
      "Implemented a high-performance validation API in Spring Boot leveraging parallel processing to verify Tesla’s live transactions with a median response time under 400 ms.",
      "Built a Microservice in Flask to migrate 20,000,000+ transaction records from an internal vertical database to a MySQL infrastructure utilizing Kafka Broker to allow for real-time analytics."
    ],
  },
  {
    logo: require("../images/Activision.jpg"),
    company: "Activision",
    role: "Software Engineer Intern",
    techStack: "Docker, Kubernetes, Vitess, AWS Redis, MySQL",
    date: "May 2024 - Aug 2024",
    bullets: [
      "Redesigned data partitioning strategies using Vitess to balance shard workloads and optimize query routing, reducing player detail retrieval times by 25% for Call of Duty Black Ops 6.",
      "Deployed a caching layer with AWS Redis to store session data, achieving a 40% reduction in retrieval times and enhancing multiplayer responsiveness."
    ],
  },
  {
    logo: require("../images/Cisco.jpg"),
    company: "Cisco",
    role: "Software Engineer Intern",
    techStack: "React, Ruby on Rails, C++, PostgreSQL",
    date: "May 2023 - Dec 2023",
    bullets: [
      "Developed an internal dashboard used by 1000+ engineers using React and Ruby on Rails, displaying virus scan data sourced from a PostgreSQL database.",
      "Contributed to eight version updates of the Linux virus scanner software, enhancing 25+ features in Ruby, including multi-threaded scan optimizations that reduced scan times by up to 15% for large file systems."
    ],
  },
  {
    logo: require("../images/SAP.jpg"),
    company: "SAP",
    role: "Software Developer Intern",
    techStack: "Golang, Python, Docker, Kubernetes",
    date: "Sept 2022 - Apr 2023",
    bullets: [
      "Configured Prometheus alerts to identify system errors for 200+ Kubernetes Pods, notifying Slack channels for 10,000+ engineers.",
      "Deployed Docker containers containing malicious code in Golang and Python to manually trigger the faulty scenarios to ensure alerts fire correctly."
    ],
  },
  {
    logo: require("../images/UBC.jpg"),
    company: "University of British Columbia",
    role: "Frontend Developer",
    techStack: "React",
    date: "May 2022 - Aug 2022",
    bullets: [
      "Deployed the UBC Integrated Engineering website with over 2000+ monthly visitors after creating the student projects and events pages in React.",
      "Created the 2nd version of the design library, updating 15+ UI components using SASS to improve mobile scalability across 50+ UBC Engineering websites.",
      "Relaunched the UBC Equip Health website after implementing new UI card formats for 100+ subpages and resolving 55+ visual bugs in CSS for cross-device compatibility."
    ],
  }
];

const Work = () => (
    <section className="section">
      <h2>Work Experience</h2>
      {experiences.map((experience, index) => (
        <div className="experience-item" key={index}>
          <img
            src={experience.logo}
            alt={`${experience.company} Logo`}
            className="company-logo"
          />
          <div className="experience-content">
            <h3>{experience.role} @ {experience.company}</h3>
            <p className="date">{experience.date}</p>
            <p>
              <strong>Tech Stack:</strong> {experience.techStack}
            </p>
            <ul>
              {experience.bullets.map((bullet, i) => (
                <li key={i}>{bullet}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </section>
  );
  

export default Work;
